<template>
  <split-pane  :min-percent='10' :default-percent='12' split="vertical" style="height:100%;background:#fff">
        <template slot="paneL">        
            <el-tree :data="treeData" 
                    :props="defaultProps" 
                    @node-click="handleNodeClick" 
                    node-key="id"   
                    :default-expanded-keys="['156']"                     
                    :style="{height: scrollerHeight,overflow:'auto' }"
                    highlight-current
                    :filter-node-method="filterNode"
                    ref="eltree"
                    :expand-on-click-node="false">
                <template v-slot="{node,data}">                                     
                    <span v-if="data.id == 158 " class="el-icon-folder"  >
                        <span class="nodeLabel">{{ node.label}}【{{dataCount158}}】</span>
                    </span>  
                    <span v-else-if="data.id == 159 " class="el-icon-folder"  >
                        <span class="nodeLabel">{{ node.label}}【{{dataCount159}}】</span>
                    </span> 
                    <span v-else-if="data.id == 160 " class="el-icon-folder"  >
                        <span class="nodeLabel">{{ node.label}}【{{dataCount160}}】</span>
                    </span> 
                    <span v-else-if="data.id == 19596 " class="el-icon-folder"  >
                        <span class="nodeLabel">{{ node.label}}【{{dataCount161}}】</span>
                    </span> 
                    <span v-else class="el-icon-folder" > 
                        <span class="nodeLabel">{{ node.label}}  </span>
                    </span>
                </template>
            </el-tree>        
        </template>
      <template slot="paneR"> 
          <div class="siderCls" >
            <el-button @click="addRec()" type="text" icon="el-icon-plus" style="margin-right:20px;"> 新增客户</el-button>           
            <span style="margin-right:6px;font-size:12px;"></span>
            <el-select  v-model="stfgSet"   size="small" @change="stfgChange" style="height:30px;width:120px">
                <el-option  label="所有状态" value="ALL" > </el-option>
                <el-option  label="已审核" value="Y" > </el-option>
                <el-option  label="待审核" value="N" > </el-option>
            </el-select>
            <span style=" margin-left:20px;font-size:12px;margin-right:6px">字段查询</span> 
            <el-select v-model="selparam" size="small" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:140px;">
                <span v-for="item in cltCol" :key='item.key'>
                    <el-option :label="item.title" :value="item.key"></el-option>
                </span>
            </el-select>       
            <el-input :placeholder="input_hold" v-model="textparam" @keyup.enter.native="queryData" size="small" style="width:320px;vertical-align:center">
              <el-select v-model="oper" slot="prepend"   style="width:120px; " >
                <span v-for="item in operArr" :key='item.IDSEQ'>
                        <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                    </span>
                </el-select> 
                <!-- <el-button  slot="append" icon="el-icon-search" @click="queryData" ></el-button>            -->
            </el-input> 
            <el-dropdown split-button type="primary" @command="advQry" @click="queryData" size="small" style="margin-left:15px;">
                查询 
                <el-dropdown-menu slot="dropdown" >
                    <el-dropdown-item  command="advance"><i class="iconfont icon-chakan-copy" style="margin-right:4px"></i>高级查询</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <i  class="iconfont icon-xuanze" style="font-size:12px;margin-left:10px;margin-right:10px"><span style="margin-left:5px;color:#606266">未审核</span></i>
            <i  class="iconfont icon-xuanze" style="color:green;font-size:12px;margin-right:10px; "><span style="margin-left:5px;color:#606266">已审核</span></i> -->
            <div>
                <el-table  :data="cltData" 
                    border                            
                    size="small"
                    ref="custab"
                    v-loading="loading"
                    element-loading-text="加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 251, 255, 0.8)"
                    :height="subHeight" 
                    highlight-current-row
                    style="overflow:auto;margin-top:1px;"
                    @row-click="matClick" > 
                    <span v-for="(item,index ) in cltCol" :key="index" >
                        <el-table-column  
                            v-if="item.thide==='N' && item.key!=='STFG'"
                            :prop="item.key"
                            :fixed="item.key==='RN'?true:false"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column> 
                        <el-table-column  v-if="item.key==='STFG'"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            :min-width="item.width">
                            <template slot-scope="scope" >
                                <el-checkbox disabled false-label="N" true-label="Y" v-model="scope.row.STFG" >&nbsp;</el-checkbox>
                            </template>
                        </el-table-column>  
                    </span>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="140">
                        <template slot-scope="scope">
                            <el-button size="small" v-if="scope.row.STFG==='Y'"  type="text" icon="el-icon-search" @click="editRec(scope.row,'N')">查看</el-button>
                            <el-button size="small" v-if="scope.row.STFG==='N'"  type="text" icon="el-icon-edit" @click="editRec(scope.row,'Y')">编辑</el-button>
                            <el-button size="small" v-if="scope.row.STFG==='N'"   type="text" icon="el-icon-check" @click="cfmRec(scope.row,'Y')">审核</el-button>
                            <el-button size="small" v-if="scope.row.STFG==='Y'"   type="text" icon="el-icon-close" @click="cfmRec(scope.row,'N')">反审核</el-button>  
                        </template>
                    </el-table-column>                       
                </el-table>
            </div>
          <div style="margin-top: 5px;margin-bottom:2px;overflow: hidden;font-size:12px">
                <div style="float: left;">
                    <Page :total="dataCount" :page-size="pageSize" show-total :current="1" @on-change="changePage"></Page>
                </div>
          </div> 
        </div>
      
      <el-dialog v-dialogDrag class="pub_dialog"  :visible.sync="addModel" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
        <div slot="title" :style="{'background':'#2b85e4','color':'white','height':'40px'}">
            <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{type==='create'?'新增记录':'编辑记录'}}</span>
        </div> 
        <div style="height:88vh;overflow-y:auto;padding-bottom:100px;">
            <div v-for="(gpitem,index) in tabFrmCol" :key="index" style="margin-bottom:15px;" >
                <div class="one-toolbar">
                    <span class="spanRow">{{gpitem.gpnm}}  
                        <el-link v-if="gpitem.gpno==='20284'" :underline="false" @click="perAdd">【新增记录】</el-link>        
                    </span>
                </div>
                <!-- 联络记录:20284,图片资料:38752  -->
                <span v-if="gpitem.gpno!=='20284' && gpitem.gpno!=='38752'">
                    <Form :model="matRow" :label-width="110" ref="matWin"  label-position="right" inline :rules="custRule" >  
                    <span v-for="(item,index) in gpitem.gplist" :key="index" >
                        <FormItem  :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO"  >                  
                            <el-date-picker type="date" :disabled="saveFlag"  v-if="item.EDCSS==='date'&item.FLDNO!=='BLDYEAR'" v-model="matRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker> 
                            <el-date-picker type="year" :disabled="saveFlag" v-else-if="item.EDCSS==='date'&item.FLDNO==='BLDYEAR'" v-model="matRow[item.FLDNO]" clearable value-format="yyyy" style="width:100%" ></el-date-picker>     
                            <el-input v-enter-number :disabled="saveFlag"  v-else-if="item.EDCSS==='number'" v-model="matRow[item.FLDNO]" style="width:100%" ></el-input>   
                            <el-cascader :disabled="saveFlag" v-else-if="item.FLDNO==='CLTYPE'" v-model="matRow[item.FLDNO]" clearable style="width:100%" 
                                filterable
                                :options="cltyArr">
                            </el-cascader> 
 
                            <!-- 企业性质（多选） -->
                            <el-select :disabled="saveFlag" multiple  filterable v-else-if="item.FLDNO==='CORPTY'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in corpArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <!-- 主营行业 -->
                            <el-select multiple  filterable v-else-if="item.FLDNO==='MAINTY'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in maintyArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <!-- 经销商等级 -->
                            <el-select  :disabled="saveFlag" v-else-if="item.FLDNO==='CLNTLVL'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option v-for="item in clntlvlArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <!-- 业务员 -->
                            <el-select  :disabled="saveFlag" v-else-if="item.FLDNO==='STAFF'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option v-for="item in staffArr" :key="item.USERNM" :value="item.USERNM" :label="item.USERNM" ></el-option>
                            </el-select> 
                            <el-select :disabled="saveFlag"  v-else-if="item.FLDNO==='STAFFNUM'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option v-for="item in staffnumArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <el-select  :disabled="saveFlag" v-else-if="item.FLDNO==='SALEAMT'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option v-for="item in saleamtArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <!-- 线索来源 -->
                            <!-- <el-select   v-else-if="item.FLDNO==='CLTSRC'"  v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in lineArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> -->
                            <Select multiple v-else-if="item.FLDNO==='CLTSRC'" v-model="matRow[item.FLDNO]" style="width:100%">                            
                                <OptionGroup :label="item.gpnm" v-for="(item,index) in lineArr" :key="index">
                                    <Option v-for="item in item.gplist" :value="item.CODE" :key="item.CODE">{{ item.CNAME}}</Option>
                                </OptionGroup>  
                            </Select>
                            <el-select :disabled="saveFlag" v-else-if="item.FLDNO==='PAYMTH'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in payArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select>   
                            <el-cascader :disabled="saveFlag" v-else-if=" item.FLDNO==='AREA'"  style="width:100%"
                                v-model="matRow[item.FLDNO]" 
                                clearable
                                filterable
                                :options="countryArr">
                            </el-cascader>
 
                            <el-select multiple :disabled="saveFlag" filterable v-else-if="item.FLDNO==='PRDTY'||item.FLDNO==='REQPRDTY'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in prdtyArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false ||saveFlag"  v-model="matRow[item.FLDNO]">
                                <span v-if="gpitem.gpno==='38818'" slot="prepend">HTTP://</span>
                            </el-input>
                        </FormItem>
                    </span>
                    </Form>
                </span>
                <!-- 产品图片:38752  -->
                <span v-if="gpitem.gpno==='38752'"> 
                    <div style="display:flex" >
                        <el-row style="width:100%" :gutter="6">
                            <el-col :span="8" v-for="(item,index) in imageTypeArr" :key="index">
                                <el-card >
                                    <span slot="header">{{item.CNAME}}</span>
                                    <div style="float:left">
                                        <div v-for="(item1,index) in videoData" :key="index" :label="item1.title"  style="float:left; ">
                                            <el-row>
                                                <el-image v-if="item1.FILETY==item.CODE" fit="scale-down" :src="item1.FILEPATH" style="width:90px;height:90px;margin-right:20px;" ></el-image>
                                            </el-row> 
                                            <el-row >
                                                <el-link v-if="item1.FILETY==item.CODE" :underline="false" @click="imgDel(item1.IDSEQ,item1.LSTSEQ,item1.FILEPATH,index)"><i class="iconfont icon-shanchu1" />  </el-link>
                                                <el-link v-if="item1.FILETY==item.CODE" :underline="false" @click="imgPreview(item1.FILEPATH)"><i class="iconfont icon-chazhao" style="margin-left:60px" />  </el-link>
                                            </el-row>                                                
                                        </div> 
                                        <div  style="float:left">
                                            <el-row>
                                                <el-link :underline="false"  >
                                                    <Upload
                                                        ref="upload"
                                                        name="upfile"
                                                        :data="uploadData"
                                                        :show-upload-list="false"
                                                        :before-upload="beforeImgFile"
                                                        :on-success="successImgFile"
                                                        :on-progress="handleProgress"
                                                        :accept="Accept"   
                                                        :disabled="saveFlag"                      
                                                        :format="['jpg','jpeg','png','gif','webp' ]"
                                                        :max-size="2048"
                                                        :on-format-error="handleFormatError"
                                                        :on-exceeded-size="handleMaxSize"
                                                        :action="uploadUrl"
                                                        style="display: inline-block;width:90px; ">                           
                                                        <div style="padding: 1px 0;width:90px;height:90px;">
                                                            <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="showClick(item.CODE)"></el-image> 
                                                        </div>                                                       
                                                    </Upload> 
                                                </el-link>
                                            </el-row>
                                            <el-row >
                                                <i class="iconfont icon-shangchuan1" style="margin-left:30px;"/> 
                                            </el-row>
                                        </div>                                         
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>   
                    </div>
                </span>
                <!-- 联络记录:20284  -->
                <span v-if="gpitem.gpno==='20284'"> 
                  <el-table  :data="perData" 
                    border                            
                    size="small"
                    ref="pertab"
                    :height="250" 
                    highlight-current-row
                    @row-click="perClick" > 
                    <el-table-column  v-for="(item ) in perCol" :key="item.key"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                    </el-table-column>  
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="150">
                        <template slot-scope="scope">
                            <el-button size="small"   type="text" icon="el-icon-edit" @click="perEdit(scope.row,scope.$index)">编辑</el-button>
                            <el-button size="small"   type="text" icon="el-icon-close" @click="perDel(scope.row,scope.$index)">删除</el-button>   
                        </template>
                    </el-table-column>                        
                  </el-table>
                </span>
            </div>
        </div>
 
        <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; " >
            <span style="float:right">
                <span style="margin-right:40px;">
                <i v-if="matRow['STFG']==='N'" style="line-height:35px; color:red;font-size:28px; " class="iconfont icon-weishenhe3"> </i>
                <i v-else-if="matRow['STFG']==='Y'" style="line-height:35px; color:red;font-size:28px; " class="iconfont icon-yishenhe1"> </i>
                <i v-else-if="matRow['STFG']==='X'" style="line-height:35px; color:red;font-size:28px; " class="iconfont icon-yizuofei"> </i>
                </span>
                <el-button type="info" @click="addModel=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                <el-button :disabled=" saveFlag" @click="modal_ok" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 保存 </el-button>  
            </span>
        </div>
      </el-dialog>
        <el-dialog class="pub_dialog"  :show-close="false" :visible.sync="perModel" width="1100px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">              
          <div slot="title" :style="{'background':'#2b85e4','color':'white','height':'40px'}">
            <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{perFlag==='create'?'新增记录':'编辑记录'}}</span>
          </div> 
            <div style="overflow-y:auto;height:88vh">
                <el-card v-for="(gpitem,index) in perGpArr" :key="index" style="margin-bottom:15px;">
                    <p slot="header" style="color:#5cadff;height:14px;line-height:14px ">
                        <span style="font-weight:600;font-size:14px;color:#5cadff">{{gpitem.gpnm}} </span> 
                        <el-link v-if="gpitem.gpno==='20284'" :underline="false" @click="perAdd">【新增记录】</el-link>        
                    </p>
                    <Form :model="perRow" :label-width="110" ref="perForm"  label-position="right" inline  :rules="ruleValidate">  
                        <span v-for="(item,index) in gpitem.gplist" :key="index" >
                            <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                                <el-date-picker type="date"  v-if="item.EDCSS==='date'" v-model="perRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                                <el-input v-enter-number  v-else-if="item.EDCSS==='number'" v-model="perRow[item.FLDNO]" style="width:100%" ></el-input>   
                                <el-checkbox border v-else-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="perRow[item.FLDNO]" style="width:100%"></el-checkbox> 
                                <el-select v-else-if="item.FLDNO==='WHAT'" v-model="perRow[item.FLDNO]" style="width:100%" >
                                   <el-option  v-for="item in whatArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                                </el-select> 
                                <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="perRow[item.FLDNO]"></el-input>
                            </FormItem>
                        </span>
                    </Form>
                </el-card>
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; " >
                <i class="iconfont icon-shanchu" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                <el-link  @click="perModel=false" :underline="false" style="margin-top:0px;"><span style="font-size:13px;margin-right:15px;">取消</span></el-link>
                <i class="iconfont icon-baocun" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                <el-link @click="perOk"  :underline="false"  ><span style="font-size:13px;margin-right:25px;">确定</span></el-link>   
            </div>
        </el-dialog> 
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:5px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
        <!-- 预览图片 -->
        <el-dialog :visible.sync="showViewer" width="950px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:15px;">图片预览</span>
            </div>
            <el-image fit="scale-down" :src="listurl"  style="width:99%;height:400px;margin-top:5px;"></el-image> 

        </el-dialog>
    </template>
  </split-pane>
</template>
<script>    
    import { getTabColOrData } from '../../api/user';
    import { filterArrObj } from '../../api/Tools';
    import { staffArray,lvlArray,paramBase,countryArray,getDate,baseCascade,paramData,whatArray} from '../../api/Select'
    import docSelect from '../docmgt/doc_select.vue'
  
    //引入el-image-viewer 
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer' 
    export default {
    name:"sys_clntinfo",
    data() {
        return {
            selectimageTy:'',
            imageTypeArr:[],//图片类型
            dataCount158:0,
            dataCount159:0,
            dataCount160:0,
            dataCount161:0,
            videoUploadPercent:0,
            loading:false,
            dataCount:0,
            stfgSet:'ALL',
            addModel:false,
            perModel:false,
            perFlag:'create',
            videoFlag:false,
            showViewer:false,//打开预览/关闭
            countryArr:[], //级联数据源
            videoSrc:'',
            ruleValidate: {
               "FIRSTNM":[{"required":true,"message":"联系人姓氏不能为空","trigger":"change"}],
               "EMPNM":[{"required":true,"message":"联系人名不能为空","trigger":"change"}],
               "TELNO":[{"required":true,"message":"手机号码不能为空","trigger":"change"}]
            },
            custRule: {},
            prdtyArr:[],  //销售产品级联数据
            type:'create',
            multiprop:{ multiple: true },
            cascadeKey:1,
            specFlag:'create',
            paramTree:'',
            LANG:'',
            Accept: "png,jpg,jpeg,gif,webp", //上传文件格式限制
            operArr:[],
            //uploadUrl:this.$store.state.baseUrl +'auth/login/uploadImg',
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
            treeData:[] ,//  树数据
            cltCol:[], //客户列（表格隐藏列）
            cntObj:{},
            payCol:[], // 
            tabFrmCol:[], //产品组表单列
            pageSize:25,
            uploadData:{}, //上传数据
            matRow:{}, //产品行
            specRow:{}, //规格行
            imgRow:{}, //图片行
            perRow:{}, //价格行
            imgType:'',  // 
            oper:'like',
            selparam:'CLNTNM',// select 绑定值
            textparam:'', // input 绑定值
            perGpArr:[], // 联络人表单分组信息
            defaultProps: 
            {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                label: 'label'
            },
            saveFlag:false,
            nodeId:'156',
            cltData:[] , // customer数据
            listurl:'',//放大的图片
            videoData:[] , //图片视频数据
            perCol:[] , //联系人字段列数据
            perData:[] , //联系人数据
            ordData:[] , //历史订单数据
            lineArr:[], //线索来源
            ordCol:[] , //历史订单列数据
            v_url:this.$store.state.queryUrl , //api请求路径     
            v_many:this.$store.state.manyUrl , //api请求路径   
            v_updurl:this.$store.state.baseUrl+'user/updateRec', //多记录查询 like模式    
            v_formurl:this.$store.state.baseUrl+'user/getFormGroup', //多记录查询 like模式  
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            input_hold:'请输入内容...',
            crcyArr:[], //货币
            payArr:[],
            cltyArr:[], //客户类别
            lvlArr:[],
            corpArr:[], //企业类型
            maintyArr:[],//主营
            stfgArr:[], //
            staffnumArr:[],//员工人数
            saleamtArr:[],//年销额
            staffArr:[],//景荣业务员
            fileName:'',
            delArr:[] ,//删除记录数组
            delImg:[] ,//删除记录数组
            rowIndex:'-1',
            allData:[] , //保存产品所有数据
            clntlvlArr:[],//经销商等级
            whatArr:[],//尊称
            promptWin:false,
            prompt:'',
            filety:'',
            notNull:[],
            stfgBool:true,
        }
    },
    watch: {
    },
    components: {
       // ElImageViewer ,
    },
    computed: {
        scrollerHeight: function() {
            return (window.innerHeight - 80) + 'px';
        }, 
        subHeight: function() {
            return (window.innerHeight - 160) + 'px';
        }, 
        tabWidth: function() {
            return (window.innerWidth - 110) + 'px';
        }, 
    },
    mounted () {  
        //尊称
        whatArray().then((res)=>{
            this.whatArr =res.data.result
        });     
        //客户类别
        baseCascade('156').then((res)=>{
            this.cltyArr =res.data
        }); 
        //销售产品
        paramData('lvl','2','V_PRD_CATEGORY').then((res)=>{
            this.prdtyArr =res.data.result
        }); 
        //一级属性
        // lvlArray().then((res)=>{
        //     this.lvlArr =res.data.result
        // });saleamtArr
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        })
        
        //经销商等级
        paramBase('39072','v_sys_base').then((res)=>{
            this.clntlvlArr =res.data.result
        });
         //主营行业
        paramBase('24344','v_sys_base').then((res)=>{
            this.maintyArr =res.data.result
        });
        //年销额
        paramBase('19776','v_sys_base').then((res)=>{
            this.saleamtArr =res.data.result
        });
        //员工人数
        paramBase('19774','v_sys_base').then((res)=>{
            this.staffnumArr =res.data.result
        });
        //企业性质
        paramBase('19709','v_sys_base').then((res)=>{
            this.corpArr =res.data.result
        });
        //记录状态
        paramBase('164','v_sys_base').then((res)=>{
            this.stfgArr =res.data.result
        });
        //线索来源
        // paramBase('19777','v_sys_base').then((res)=>{
        //     console.log(res.data.result)
        //     this.lineArr =res.data.result
        // });
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'V_BASE_GPNM','parentno','19777','gpnm','','','','').then((res) => {
            this.lineArr = res.data.result               
        });
        //currency来源
        paramBase('19665','v_sys_base').then((res)=>{
            this.crcyArr =res.data.result
        });
        //贸易方式
        paramBase('38932','v_sys_base').then((res)=>{
            this.payArr =res.data.result
        });
        //客户图片类型
        paramBase('39156','v_sys_base').then((res)=>{
            this.imageTypeArr =res.data.result
        });
        //国家
        countryArray().then((res)=>{
            this.countryArr =res.data
        });
        //客户资料分组 
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'v_sys_form_fld','idseq','67','gpnm,gpsort','','','',' order by gpsort').then((res) => {
            this.tabFrmCol = res.data.result                      
        });
        //联络资料分组 
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'v_sys_form_fld','idseq','38817','gpnm,gpsort','','','',' order by gpsort').then((res) => {
            this.perGpArr = res.data.result             
        });
        //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','67','fldty','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.custRule =ruleObj
        });
    },
    created () {  
      this.qryClntDataCount( 'stfg','%')  
      this.getData()    
      this.getTabCol() //产品表列字段
      this.qryClntData(158,'stfg','N')  
      this.staffArraysearch()
   
    },
    methods: { 
        advQry(command){
            this.promptWin =true
            this.prompt ='正在开发中...'+command
        },
        showClick(val){
            this.filety =val
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            this.videoFlag = true
            this.videoUploadPercent = Math.floor(event.percent)
        },
        staffArraysearch(){
            this.$axios(this.$store.state.nologinUrl, {
                method:'post',
                data:{p_key:'stfg',p_value:'19729',p_key2:'deptno',p_value2:'销售部',p_table:'V_HR_EMP' }
            }).then((res)=>{
                this.staffArr=res.data.result
            })
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.pageSize;
            var _end = index * this.pageSize;
            this.cltData = this.allData.slice(_start,_end);
        },  
 
        //状态查询
        stfgChange(){
            if (this.stfgSet==='ALL'){
                this.qryClntData(this.nodeId,'stfg','%')  
            }else{
                this.qryClntData(this.nodeId,'stfg',this.stfgSet)  
            }
        },  
        queryData(){
            this.qryClntData(this.nodeId,this.selparam,this.textparam)
        },
 
        //联络记录 编辑、删除
        perEdit(row,index){
            this.rowIndex =index
            this.perModel =true
            this.perFlag='edit'    
        },
        perDel(row,index){
            this.$Modal.confirm({
                title: '删除确认',
                content: '<p>你确定要删除此行记录吗</p>',
                onOk: () => {
                    this.perData.splice(index,1)
                    this.delArr.push(row)
                }
            });
        },
        //联系人  保存
        perClick(row){
            //js 对象的赋值是引用赋值，传递的是地址，如果copy出一份进行值的改变，就会引起被copy值一起改变，这时要采用这样
            //json.parse()用于将一个json字符串转换为对象，并返回转换后的对象
            this.perRow =JSON.parse(JSON.stringify(row))  //深拷贝
        },
        perOk(){
            var verify = /^1[3456789]\d{9}$/;
            if(!this.perRow.EMPNM){
                this.prompt ='联系人名不能为空' 
                this.promptWin=true  
            }else if(!this.perRow.FIRSTNM){
                this.prompt='联系人姓氏不能为空'  
                this.promptWin=true               
            }else if(!this.perRow.TELNO){
                this.prompt='手机号码不能为空'
                this.promptWin=true                             
            }else{
                this.perModel =false 
                if (this.perFlag==='edit' ){ //编辑时要替换数组记录
                    this.perData.splice(this.rowIndex,1,this.perRow)
                }else{
                    this.perData.push(this.perRow)
                }
            }
        },

        //保存记录
        modal_ok(){           
            let prdty_=''
            let area_=''
            let reqprdty_=''
            let cltype_=''
            let corpty_=''
            let mainty_=''
            let cltsrc_=''
            this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                if (!this.matRow[item.FLDNO] ){
                    this.stfgBool =false 
                    this.promptWin=true
                    this.prompt=item.FLDNM+'不能为空'
                    return true
                }else{
                    this.stfgBool =true  
                }
            })
            if(stfgBool){
                //线索来源
                if (typeof(this.matRow['CLTSRC'])!=='undefined' && this.matRow['CLTSRC']!==''){
                    cltsrc_ =this.matRow['CLTSRC'].join(',') 
                }
                if (typeof(this.matRow['MAINTY'])!=='undefined' && this.matRow['MAINTY']!==''){
                    mainty_ =this.matRow['MAINTY'].join(',') 
                }
                if (typeof(this.matRow['CORPTY'])!=='undefined' && this.matRow['CORPTY']!==''){
                    corpty_ =this.matRow['CORPTY'].join(',') 
                }
                // 销售产品、需求产品、客户类型、国家 这些是值是数组形式，存储时以字符串形式，显现时要转换为数组
                if (typeof(this.matRow['PRDTY'])!=='undefined' && this.matRow['PRDTY']!==''){
                    prdty_ =this.matRow['PRDTY'].join(',') 
                }
                if (typeof(this.matRow['REQPRDTY'])!=='undefined' && this.matRow['REQPRDTY']!==''){
                    reqprdty_ =this.matRow['REQPRDTY'].join(',') 
                }
                if (typeof(this.matRow['AREA'])!=='undefined' && this.matRow['AREA']!==''){
                    area_ =this.matRow['AREA'].join(',') 
                }
                if (typeof(this.matRow['CLTYPE'])!=='undefined' && this.matRow['CLTYPE']!==''){
                    cltype_ =this.matRow['CLTYPE'].join(',') 
                }
                Object.assign(this.matRow,{PRDTY:prdty_,REQPRDTY:reqprdty_,AREA:area_,CLTYPE:cltype_,CORPTY:corpty_,MAINTY:mainty_,CLTSRC:cltsrc_ } )
                //console.log(JSON.stringify(this.matRow))
                this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.matRow), p_table:'bs_clnt',frmid:'67'},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        this.addModel =false 
                        //联络人保存：批量保存  res.data.idseq
                         this.$axios({   
                            method: 'post',
                            url:this.$store.state.baseUrl+'user/batDtlSave?username='+this.v_username +'&password='+this.v_password,
                            data: {p_json:JSON.stringify(this.perData), p_table:'BS_CLNTEMP',p_idseq:res.data.idseq,p_action:'S' },
                         })
                         //联络人删除
                         if (this.delArr.length>0) {
                            this.$axios({   
                                method: 'post',
                                url:this.$store.state.baseUrl+'user/batDtlSave?username='+this.v_username +'&password='+this.v_password,
                                data: {p_json:JSON.stringify(this.delArr), p_table:'BS_CLNTEMP',p_idseq:res.data.idseq,p_action:'D' },
                            })
                         }
                         //图片记录删除
                         if (this.delImg.length>0) {
                            this.$axios({   
                                method: 'post',
                                url:this.$store.state.baseUrl+'user/batDtlSave?username='+this.v_username +'&password='+this.v_password,
                                data: {p_json:JSON.stringify(this.delImg), p_table:'BS_CLNTIMG',p_idseq:res.data.idseq,p_action:'D' },
                            })
                            for (let k=0;k<this.delImg.length;k++){
                                this.$axios({   
                                    method: 'post',
                                    url:this.$store.state.baseUrl+'oss/deleteFile?username='+this.v_username +'&password='+this.v_password,
                                    data: {p_key:this.delImg[k].PATH},
                                }).then(res=>{
                                    console.log(res.data.code)
                                })
                            }
                         }
                        //查询数据
                        this.queryData()
                    }else{
                        this.prompt =res.data.result
                        this.promptWin=true 
                    }        
                }) 
            }          
        },
 
        matOk(){

        },
        // 变更状态记录
        cfmRec(row,val){
            let v_comm=''
            if (val==='Y'){
                v_comm ='审核'
            }else{
                v_comm ='反审核'
            }
            this.$confirm('你确定要'+v_comm+'此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'BS_CLNT',p_cond:' set stfg=\''+val+'\' '},
                    }).then(res=>{      
                        if(res.data.code =='200')  {           
                            Object.assign(row,{STFG:val})
                        }else{
                            this.prompt =res.data.result
                            this.promptWin=true 
                        }        
                    }) 
 
            }).catch(()=>{}) 
        },
 
        //修改记录
        editRec(row,val){
            this.addModel =true
            this.type ='edit'
            this.delArr=[] //初始化 删除记录数组
            this.delImg=[]
            if (val==='N'){
                this.saveFlag=true
            }else{
                if (row['STFG']==='Y')  {  
                    this.saveFlag=true
                }else{
                    this.saveFlag=false
                }
            }
        },
        //新增记录
        addRec(){
            this.addModel =true
            this.type ='create'
            this.matRow ={'IDSEQ':''}
            this.saveFlag =false
            this.videoData =[]  //图片数组
            this.perData=[]  //联络资料数组
            this.delArr=[]
            this.delImg=[]
        },
        //联系人新增记录
        perAdd(){
            this.perModel =true
            this.perFlag ='create'
            this.perRow ={}
 
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出2M'
            this.promptWin=true
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 jpg or png' 
            this.promptWin=true
        },
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            this.qryImgData(this.matRow['IDSEQ'])
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {  
            if(this.matRow['IDSEQ'])  {          
                this.uploadData = {
                    p_user:this.v_username,
                    p_idseq:this.matRow['IDSEQ'],
                    p_table:'BS_CLNTIMG',
                    p_filety:this.filety,
                }
                let promise = new Promise(resolve => {
                this.$nextTick(function() {
                        resolve(true);
                    });
                });
                return promise;  
            }else{
                this.promptWin=true
                this.prompt='请先保存资料后再上传'
            }
        },
        //查询视频数据
        qryImgData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_CLNTIMG','idseq',idseq).then((res) => {
                this.videoData=[]
                if (res.data){
                    this.videoData =res.data
                }
            })
        },
        //查询联系人数据
        getPerData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_CLNTemp','idseq',idseq).then((res) => {
                this.perData=[]
                if (res.data){
                    this.perData =res.data     
                }
            })
        },
        //查询customer信息
        qryClntDataCount( key,value){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+158+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                this.dataCount158 =res.data.length //成交客户
            })
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+159+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                this.dataCount159 =res.data.length //潜在客户
            })
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+160+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                this.dataCount160 =res.data.length //不信任客户
            })
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+19596+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                this.dataCount161 =res.data.length //线索客户
            })
        },
 
        //查询customer信息
        qryClntData(nodeId,key,value){
            let vm=this ,v_cond=''
            vm.loading=true
            if (this.oper=='like'){
                v_cond =' and instr(cltype,\''+nodeId+'\')>0 and '+key+' like \'%'+value+'%\''
            }else{
               v_cond =' and instr(cltype,\''+nodeId+'\')>0 and '+key+ this.oper+' \''+value+'\'' 
            }
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',v_cond).then((res) => {
                this.cltData=[]
                for (var k=0; k<res.data.length; k++){
                    let obj_={}
                    let row_=res.data[k]
                    let prdty_=''
                    let area_=''
                    let reqprdty_=''
                    let cltype_=''
                    let corpty_=''
                    let mainty_=''
                    let cltsrc_=''
                    if (row_.CLTSRC){
                        cltsrc_ =row_['CLTSRC'].split(',')
                    }
                    if (row_.MAINTY){
                        mainty_ =row_['MAINTY'].split(',')
                    }
                    if (row_.CORPTY){
                        corpty_ =row_['CORPTY'].split(',')
                    }
                    if (row_.PRDTY){
                        prdty_ =row_['PRDTY'].split(',')
                    }
                    if (row_.AREA){
                        area_ =row_['AREA'].split(',')
                    }
                    if (row_.REQPRDTY){
                        reqprdty_ =row_['REQPRDTY'].split(',')
                    }
                    if (row_.CLTYPE){
                        cltype_ =row_['CLTYPE'].split(',')
                    }
                    Object.assign(obj_,res.data[k],{'PRDTY':prdty_,'AREA':area_,'CLTYPE':cltype_,'REQPRDTY':reqprdty_,'CORPTY':corpty_,'MAINTY':mainty_,'CLTSRC':cltsrc_ })
                    vm.cltData.push(obj_)
                } 
                //保存取到 所有数据
                vm.allData =vm.cltData
                vm.dataCount =res.data.length //总条数
                // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                if (res.data.length < vm.pageSize){
                    vm.cltData = vm.allData
                }else{
                    vm.cltData =vm.allData.slice(0,vm.pageSize) //返回选定元素的子数组，不包含结尾元素
                }
                vm.loading=false
                //希望在 DOM 元素中某些属性发生变化之后重新应用该插件
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    this.$refs.custab.doLayout();
                    //this.$refs.pertab.doLayout();
                }) 
            })
        },
        //图片预览
        imgPreview(path){
            this.showViewer=true
            this.listurl=path
        },
        //图片删除，操作数组
        imgDel(idseq,lstseq,path,index){
            this.$confirm('你确定要删除此图片吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //发起删除请求
                this.videoData.splice(index, 1); 
                this.delImg.push({IDSEQ:idseq,LSTSEQ:lstseq,PATH:path.replace('https://kreco.oss-accelerate.aliyuncs.com/','')})         
            }).catch(()=>{})
        },
        // 产品单 行事件
        matClick(row){
            this.matRow =JSON.parse(JSON.stringify(row))
            this.getPerData(row.IDSEQ)
            this.qryImgData(row.IDSEQ)
        },
        elchange(val){  // 这个val 就是 el-select 的 value
            let obj ={}
            obj =this.cltCol.find(item=>{
                return item.key===val
            })
            if (obj)
            this.input_hold='请输入'+obj.title+' 的值'
        },
        //获取表列头字段
        getTabCol(){
            var vm=this
            //客户列
            getTabColOrData(vm.v_many,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','67','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                if (res.data.code==='200'){
                    for (let k=0; k<res.data.result.length; k++){
                        vm.cltCol.push({ //向数组的开头 添加序列号
                            title: res.data.result[k].title,
                            key: res.data.result[k].key,
                            align: res.data.result[k].align,
                            width: res.data.result[k].width,
                            thide: res.data.result[k].THIDE,
                            fhide: res.data.result[k].FHIDE,
                            sortable: res.data.result[k].sortable?true:false,
                        })
                    }
                }else{
                   this.prompt ='系统断开连接，请重新登录'
                   this.promptWin=true 
                }
            });
 
            //联系人
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','38817','','','thide','N',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.perCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,
                        edcss: res.data[k].EDCSS,
                        fhide: res.data[k].FHIDE,
                        disabled: res.data[k].DISABLED,
                        sortable: res.data[k].sortable?true:false,
                    })
                }
            });
            //历史订单
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','67','fty','HEAD3','thide','N',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.ordCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,
                        edcss: res.data[k].EDCSS,
                        fhide: res.data[k].FHIDE,
                        disabled: res.data[k].DISABLED,
                        sortable: res.data[k].sortable?true:false,
                    })
                }
            });
        },
        handleNodeClick(data){
            this.stfgSet='ALL'
            this.nodeId =data.id
            if (data.id)
            this.qryClntData(data.id,this.selparam,this.textparam)
        }  ,
        //节点过滤
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 获取 树形控件数据
        getData() {  
            var vm=this;
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                data: {p_table:'V_CLT_TREE'}
            }).then(function(res){                  
                vm.treeData =res.data;   
                if(res.data.length > 0 ){
                    //'nextTick()' 下次dom更新时触发回调函数
                    vm.$nextTick().then(() =>{
                        //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                        vm.$refs.eltree.setCurrentKey(vm.nodeId)
                    })
                }
                        
                  
            }).catch(function(err){})   
                                      
        },
    },
 }
</script>

<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .imgcss{
        border:1px solid #5cadff;
        width:90px;
        height:80px;
        display:flex;
        flex-direction:column;
        text-align:center;
        margin-right:18px;
        margin-left:10px;
        margin-top:10px;
        margin-bottom:5px;
    }
    .imgcss:hover{
        cursor: pointer;
        border:1px solid #409EFF;
        z-index: 1;
        .picsrch{
            right:3px;
            top:1px; 
            z-index: 99;
            display:inline;
        }
        .fileset{
            left:3px;
            top:1px; 
          //  position:absolute;
            display:inline;
        }
    }
    .picsrch{
        right:3px;
        top:1px; 
        z-index: 99;
        display:none;
    }
    .fileset{
        left:3px;
        top:1px; 
       // position:absolute;
        display:none;
    }
    .item-width  {
        width: 32%;
        color:#409EFF;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style> 
<style lang="less">
 
    .messageBox .el-message-box__content{
        border-top: 1px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 25px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
    .row-width  {
        width: 98.5%;
    }
    .el-card__header{
        //height:35px;
        vertical-align: middle;
    }
 
    .el-card__header  {
        height:40px;
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
</style>
